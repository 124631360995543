import { Col, Typography, Layout, Row, List, Input, Button } from "antd";
import {
  BiLogoLinkedin,
  BiLogoTwitter,
  BiLogoYoutube,
  BiLogoInstagram,
} from "react-icons/bi";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

const { Footer } = Layout;
const { TextArea } = Input;

const ClientFooter = () => {
  const navigate = useNavigate();
  return (
    <Footer className="footer" style={{ height: "auto", padding: "0" }}>
      <Row
        style={{
          justifyContent: "center",
          padding: "0 10px",
        }}
      >
        <Col xs={24} md={20}>
          <Row style={{ justifyContent: "center" }}>
            <Col
              xs={24}
              md={10}
              style={{ padding: "30px 0", fontFamily: "Lato" }}
              className={"footer-column"}
            >
              <Row>
                <Col xs={24} md={18}>
                  <div style={{ padding: "10px 30px 0px 10px" }}>
                    <Typography.Title
                      className="fontFamily1"
                      style={{ color: "#333d47", fontSize: 30 }}
                    >
                      About
                    </Typography.Title>
                    <Typography.Text
                      className="fontFamily1"
                      style={{ color: "#333d47", fontSize: 16 }}
                    >
                      We're a passionate group of educators working together to
                      create the best online learning experiences for i12
                      Excellence and adult learners like you.
                    </Typography.Text>
                    <br />
                    <br />
                    <Row>
                      <Col xs={6} lg={3}>
                        <BiLogoLinkedin
                          style={{
                            color: "#333d47",
                            fontSize: 25,
                            cursor: "pointer",
                          }}
                        />
                      </Col>
                      <Col xs={6} lg={3}>
                        <BiLogoTwitter
                          style={{
                            color: "#333d47",
                            fontSize: 25,
                            cursor: "pointer",
                          }}
                        />
                      </Col>
                      <Col xs={6} lg={3}>
                        <BiLogoYoutube
                          style={{
                            color: "#333d47",
                            fontSize: 25,
                            cursor: "pointer",
                          }}
                        />
                      </Col>
                      <Col xs={6} lg={3}>
                        <BiLogoInstagram
                          style={{
                            color: "#333d47",
                            fontSize: 25,
                            cursor: "pointer",
                          }}
                        />
                      </Col>
                    </Row>
                    <br />
                    {/* <Row>
                      <Button
                        style={{
                          marginTop: "10px",
                          padding: "0px 30px",
                          cursor: "pointer",
                          background: "#00a79d",
                          color: "white",
                          fontWeight: "bold",
                          borderRadius: "50px",
                        }}
                        onClick={() => navigate("/signup")}
                        size="large"
                      >
                        Register Now
                      </Button>
                      &emsp;
                      <Button
                        style={{
                          marginTop: "10px",
                          padding: "0px 30px",
                          background: "#00a79d",
                          color: "white",
                          fontWeight: "bold",
                          borderRadius: "50px",
                          cursor: "pointer",
                        }}
                        size="large"
                      >
                        Apply Now
                      </Button>
                    </Row> */}
                  </div>
                </Col>
              </Row>
            </Col>

            <Col
              xs={24}
              md={6}
              style={{ padding: "30px 0", fontFamily: "Lato" }}
              className={"footer-column"}
            >
              <div style={{ padding: "10px 30px 0px 10px" }}>
                <Typography.Title
                  className="fontFamily1"
                  style={{ color: "#333d47", fontSize: 30 }}
                >
                  Quick Links
                </Typography.Title>
                <List
                  className="footer-list"
                  size="small"
                  style={{ fontSize: 18 }}
                >
                  <List.Item
                    key="home"
                    className="footerLink"
                    onClick={() => navigate("/")}
                  >
                    Home
                  </List.Item>
                  <List.Item
                    key="about"
                    className="footerLink"
                    onClick={() => navigate("/about-us")}
                  >
                    About
                  </List.Item>
                  {/* <List.Item key="services" className="footerLink">
                    Services
                  </List.Item>
                  <List.Item key="book_online" className="footerLink">
                    Book Online
                  </List.Item> */}
                  <List.Item
                    key="contact_us"
                    className="footerLink"
                    onClick={() => navigate("/contact-us")}
                  >
                    Contact Us
                  </List.Item>
                </List>
                <br />
              </div>
            </Col>

            <Col
              xs={24}
              md={6}
              style={{ padding: "30px 0", fontFamily: "Lato" }}
              className={"footer-column"}
            >
              <div style={{ padding: "10px 30px 0px 10px" }}>
                <Typography.Title
                  className="fontFamily1"
                  style={{ color: "#333d47", fontSize: 30 }}
                >
                  Join Us
                </Typography.Title>
                {/* <Typography.Text
                  className="fontFamily1"
                  style={{
                    color: "#333d47",
                    fontSize: 18,
                    textDecoration: "underline",
                  }}
                >
                  Training
                </Typography.Text> */}
                <List
                  className="footer-list"
                  size="small"
                  style={{ fontSize: 18 }}
                >
                  <List.Item
                    key="elementary_tutoring"
                    className="footerLink"
                    onClick={() => navigate("/signup")}
                  >
                    SignUp as Consultant
                  </List.Item>
                  <List.Item
                    key="middle_school_tutoring"
                    className="footerLink"
                    onClick={() => navigate("/signup")}
                  >
                    SignUp with Tutor
                  </List.Item>
                  <Link
                      to={
                        "https://react.customdev.solutions/excellence/excellencestudent/request"
                      }
                    >
                  <List.Item key="high_school_tutoring" className="footerLink">
                    
                      SignUp with Student
                  </List.Item>
                    </Link>
                  {/* <List.Item key="adult_tutoring" className="footerLink">
                    Adult Training
                  </List.Item> */}
                </List>
                <br />

                {/* <Typography.Text
                  className="fontFamily1"
                  style={{
                    color: "#333d47",
                    fontSize: 18,
                    textDecoration: "underline",
                  }}
                >
                  Consulting
                </Typography.Text>
                <List
                  className="footer-list"
                  size="small"
                  style={{ fontSize: 18 }}
                >
                  <List.Item key="academic_coaching" className="footerLink">
                    Academic Consulting
                  </List.Item>
                  <List.Item key="career_coaching" className="footerLink">
                    Career Consulting
                  </List.Item>
                  <List.Item key="mindset_coaching" className="footerLink">
                    Mindset Consulting
                  </List.Item>
                  <List.Item key="conflict_coaching" className="footerLink">
                    Conflict Consulting
                  </List.Item>
                  <List.Item
                    key="goal_achievement_coaching"
                    className="footerLink"
                  >
                    Goal Achievement Consulting
                  </List.Item>
                  <List.Item key="focus_coaching" className="footerLink">
                    Focus Consulting
                  </List.Item>
                </List> */}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row
        style={{
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
        }}
      >
        <Col xs={24} md={18}>
          <Row>
            <Col xs={24} md={14}>
              <p className="footerLink">
                All Rights Reserved. Proudly Designed by{" "}
                <a style={{ color: "#333d47", textDecoration: "underline" }}>
                  National Logo Design
                </a>
              </p>
            </Col>
            <Col xs={24} md={10}>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  placeContent: "center",
                }}
              >
                <p className="footerLink">Terms & Conditions</p>
                &emsp;
                <p className="footerLink">Privacy Policy</p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Footer>
  );
};

export default ClientFooter;
