import React from "react";
import { Form, Checkbox, Input, Select, Col, Row, Typography, Layout, Card, Button, Radio, DatePicker, Upload } from "antd";
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Post } from "../../config/api/post";
import { REQUEST } from "../../config/constants/api";
import { addUser, removeUser } from "../../redux/slice/authSlice";
import swal from "sweetalert";
import { BiSolidCloudUpload } from "react-icons/bi";

//icons
import { IoMdPin } from "react-icons/io";
import { FaEnvelope } from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { FiMail, FiLock } from "react-icons/fi";

const grades = [
  { value: "Kindergarten", label: "Kindergarten" },
  { value: "1st Grade", label: "1st Grade" },
  { value: "2nd Grade", label: "2nd Grade" },
  { value: "3rd Grade", label: "3rd Grade" },
  { value: "4th Grade", label: "4th Grade" },
  { value: "5th Grade", label: "5th Grade" },
  { value: "6th Grade", label: "6th Grade" },
  { value: "7th Grade", label: "7th Grade" },
  { value: "8th Grade", label: "8th Grade" },
  { value: "9th Grade", label: "9th Grade" },
  { value: "10th Grade", label: "10th Grade" },
  { value: "11th Grade", label: "11th Grade" },
  { value: "12th Grade", label: "12th Grade" }
];

const statesAndTerritories = [
  "Alabama",
  "Alaska",
  "American Samoa",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District of Columbia",
  "Florida",
  "Georgia",
  "Guam",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Northern Mariana Islands",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "U.S. Virgin Islands",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
  "Armed Forces Americas",
  "Armed Forces Europe",
  "Armed Forces Pacific"
];

const testsArray = [
  "NYSTP",
  "NYSED",
  "NYSTCE",
  "NYSESLAT",
  "NYSSMA",
  "CAASPP",
  "CELDT",
  "SBAC",
  "CHSPE",
  "ELPAC",
  "CAST",
  "CAA",
  "STAAR",
  "MAP",
  "CBA",
  "OSTP",
  "NMSBA",
  "ACTAAP",
  "LEAP",
  "PARCC",
  "SBAC",
  "MCAS",
  "PSSA",
  "NJSLA",
  "GATE",
  "EOC",
  "ACT",
  "SAT",
  "PSAT/NMSQT",
  "AP",
  "IB",
  "NAEP",
  "TELPAS",
  "TEKS",
  "TSI",
  "Utah Core Standards Assessments",
  "SBAC",
  "Virginia SOL",
  "WCAS",
  "WVGSA",
  "Wisconsin Forward Exams",
  "Wyoming TOPP",
  "WCPSA",
  "ACAP",
  "AMP",
  "AzMERIT",
  "ACTAAP",
  "CAASPP",
  "CMAS",
  "SBAC",
  "DCAS",
  "FSA",
  "GMAS",
  "HSA",
  "ISAT",
  "IAR",
  "ISTEP+",
  "Iowa Assessments",
  "KAP",
  "K-PREP",
  "LEAP",
  "MEA",
  "MCAP",
  "MCAS",
  "M-STEP",
  "MCA",
  "MAAP",
  "MAP",
  "MontCAS",
  "NSCAS"
]

const stateData = statesAndTerritories?.map(val => ({ value: val, label: val }))

function Requests() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [form] = Form.useForm();

  const onFinish = (data) => {
    let values = { ...data, privacyPolicyAccept: Boolean(data?.privacyPolicyAccept?.length) }
    console.log("Success:", values);
    setLoading(true);

    Post(REQUEST.addRequest, values)
      .then((response) => {
        console.log("response", response)
        setLoading(false);
        if (response?.data?.status) {
          form.resetFields()
          swal("Success!", "Thankyou for your message. Our team will get back to you soon", "success");
        } else if (!response?.data?.status) {
          swal("Error!", response?.data?.message?.toString() || "Something went wrong", "error");
        }
      })
      .catch((e) => {
        console.log(":::;", e);
        setLoading(false);
        swal("Error!", e?.toString() || "Something went wrong", "error");
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47" }}
        >
          {<> Register</>}
        </Typography.Title>
      </Row>

      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={20}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="infoBox"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Form
                layout="vertical"
                name="basic"
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="First Name*"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your first name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter FullName"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Last Name*"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please input your last name",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder="Enter LastName"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      // {...restField}
                      label="Age"
                      name="age"
                      rules={[
                        {
                          required: true,
                          message: "Please enter start date ",
                        },
                      ]}
                    >
                      <DatePicker
                        style={{ width: "100%" }}
                        size="large"
                        // picker="month"
                        placeholder="Date of Birth"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Grade Level*"
                      name="grade"
                      rules={[
                        {
                          required: true,
                          message: "Please input phone number",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        className="filterSelectBox"
                        placeholder=""
                        // value={filter.status}
                        // onChange={(e) => handleStatusChange(e)}
                        style={{
                          width: "100%",
                          // marginBottom: "10px",
                          textAlign: "left",
                          backgroundColor: '#efefef9a'
                        }}
                        options={grades}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={20}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Select State"
                      name="state"
                      rules={[
                        {
                          required: true,
                          message: "Select State",
                        },
                      ]}
                    >
                      <Select
                        size={"large"}
                        className="filterSelectBox"
                        placeholder=""
                        // value={filter.status}
                        // onChange={(e) => handleStatusChange(e)}
                        style={{
                          width: "100%",
                          // marginBottom: "10px",
                          textAlign: "left",
                          backgroundColor: '#efefef9a'
                        }}
                        options={stateData}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="What School District?"
                      name="district"
                      rules={[
                        {
                          required: true,
                          message: "Fill in the blank.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder=""
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Email (Parents If Under 18)"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Fill in the blank.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder=""
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Phone (If Over 18)"
                      name="phone"
                      rules={[
                        {
                          required: true,
                          message: "Fill in the blank.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder=""
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                {/* 
                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Password*"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        placeholder="Enter Password"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Confirm Password*"
                      name="confirmPassword"
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password",
                        },
                      ]}
                    >
                      <Input.Password
                        size="large"
                        placeholder="Enter Confirm Password"
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row> */}

                <Row gutter={20}>
                  <Col xs={24} md={24} style={{ marginTop: 20 }}>

                    <Form.Item
                      label="Which Subjects Are You Interested In Tutoring? (Select All That Apply)(Required)"
                      name="subjects"
                      rules={[
                        {
                          required: true,
                          message: "Fill in the blank.",
                        },
                      ]}
                    >
                      <Checkbox.Group
                        className="subjectsGroup"
                        // onChange={(e)=>setSearchFilter({...searchFilter,days:e})}
                        options={[
                          "Science General",
                          "Science Biology",
                          "Science Chemistry",
                          "Science Physics",
                          "Math General",
                          "Math Geometry",
                          "Math Algebra",
                          "Math Calculus",
                          "English Reading",
                          "English Writing",
                          "English Literature",
                          "Social Studies U.S. History",
                          "Social Studies World History",
                          "Social Studies Civics",
                        ]}
                        // value={searchFilter.days}

                        style={{
                          display: "flex",
                          flexDirection: "row",
                          rowGap: "10px",
                          columnGap: "40px",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={24} style={{ marginTop: 20 }}>

                    <Form.Item
                      label="Which Subjects Are You Interested In Tutoring? (Select All That Apply)(Required)"
                      name="tests"
                      rules={[
                        {
                          required: true,
                          message: "Fill in the blank.",
                        },
                      ]}
                    >
                      <Checkbox.Group
                        className="subjectsGroup"
                        // onChange={(e)=>setSearchFilter({...searchFilter,days:e})}
                        options={testsArray}
                        // value={searchFilter.days}

                        style={{
                          display: "flex",
                          flexDirection: "row",
                          rowGap: "10px",
                          columnGap: "40px",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20} style={{ marginTop: 20 }}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Languages Other Than English (LOTE)"
                      name="languages"
                      rules={[
                        {
                          required: true,
                          message: "Fill in the blank.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder=""
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="AP Exams"
                      name="apExam"
                      rules={[
                        {
                          required: true,
                          message: "Fill in the blank.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder=""
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Please Describe Your Current Performance In The Subject(S) You Selected."
                      name="performance"
                      rules={[
                        {
                          required: true,
                          message: "Fill in the blank.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder=""
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>


                <Row gutter={20}>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Parent Name: (If Under 18)"
                      name="parentName"
                      rules={[
                        {
                          required: true,
                          message: "Fill in the blank.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder=""
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Form.Item
                      label="Parent Phone Number: (If Under 18)"
                      name="parentPhone"
                      rules={[
                        {
                          required: true,
                          message: "Fill in the blank.",
                        },
                      ]}
                    >
                      <Input
                        size="large"
                        placeholder=""
                        className="signupFormInput"
                      />
                    </Form.Item>
                  </Col>
                </Row>


                <Row gutter={20}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Do You Agree To Our Privacy Policy And Terms Of Service?(Required)"
                      name="privacyPolicyAccept"
                      rules={[
                        {
                          required: true,
                          message: "Fill in the blank.",
                        },
                      ]}
                    >
                      <Checkbox.Group

                        className="subjectsGroup"
                        // onChange={(e)=>setSearchFilter({...searchFilter,days:e})}
                        options={[
                          "Yes"
                        ]}
                        // value={searchFilter.days}

                        style={{
                          display: "flex",
                          flexDirection: "row",
                          rowGap: "10px",
                          columnGap: "40px",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20} style={{ marginTop: 30 }}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="What Are Your Goals For Your Student In The Selected Subjects?"
                      name="goals"
                      rules={[
                        {
                          required: true,
                          message: "Please input your bio",
                        },
                      ]}
                    >
                      <Input.TextArea
                        size="large"
                        placeholder="Example response: &quot;I want to improve my grades in science and get help with preparing for exams.&quot;"
                        className=""
                        rows={4}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="How Does Your Student Learn Best?"
                      name="learnBest"
                      rules={[
                        {
                          required: true,
                          message: "Fill in the blank.",
                        },
                      ]}
                    >
                      <Checkbox.Group

                        className="subjectsGroup"
                        // onChange={(e)=>setSearchFilter({...searchFilter,days:e})}
                        options={[
                          "Visual (e.g., diagrams, videos)",
                          "Auditory (e.g., lectures, discussions)",
                          "Reading/Writing (e.g., notes, books)",
                          "Kinesthetic (hands-on activities)"
                        ]}
                        // value={searchFilter.days}

                        style={{
                          display: "flex",
                          flexDirection: "row",
                          rowGap: "10px",
                          columnGap: "40px",
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20} style={{ marginTop: 30 }}>
                  <Col xs={24} md={24}>
                    <Form.Item
                      label="Is There Anything Else You Would Like Us To Know To Better Assist You?"
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please input your bio",
                        },
                      ]}
                    >
                      <Input.TextArea
                        size="large"
                        placeholder="Let us know"
                        className=""
                        rows={4}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                  >
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                </Form.Item>
              </Form>

              <Typography.Text
                className="fontFamily1"
                style={{
                  fontSize: "14px",
                  color: "#3C3C3B",
                  textAlign: "left",
                  marginTop: 0,
                  marginBottom: 30,
                }}
              >
                <>
                  Already have an account{" "}
                  <span
                    onClick={() => navigate("/signin")}
                    style={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    Login Now
                  </span>
                </>
              </Typography.Text>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  )
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Row
        className="blueBackground"
        style={{
          height: "45vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <Typography.Title
          className="fontFamily1"
          style={{ fontSize: "30px", color: "#333d47", marginTop: 20, marginBottom: 0 }}
        >
          {<> Request</>}
        </Typography.Title>
        <Typography.Text
          className="fontFamily1"
          style={{ fontSize: "18px", color: "#333d47", margin: 10 }}
        >
          {<> Submit your request to register as an Organization</>}
        </Typography.Text>
      </Row>



      {/* section 2 */}
      <Row
        className="whiteBackground"
        style={{ backgroundColor: "white", justifyContent: "center" }}
      >
        <Col xs={24} md={10}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",

              padding: "50px 20px",
            }}
          >
            <Card
              className="contactCard1"
              bordered={false}
              style={{
                width: "100%",
                padding: "20px",
              }}
            >
              <Form
                layout="vertical"
                name="basic"
                form={form}
                className="contactForm"
                labelCol={{
                  span: 0,
                }}
                wrapperCol={{
                  span: 24,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  label="Full Name*"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input your full name!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter FullName"
                    className="ContactFormInput"

                  />
                </Form.Item>

                <Form.Item
                  label="Email*"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please input valid email!",
                    },
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Email"
                    className="ContactFormInput"

                  />
                </Form.Item>

                <Form.Item
                  label="Phone*"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter Phone Number"
                    className="ContactFormInput"

                  />
                </Form.Item>

                <Form.Item
                  label="Organization Name*"
                  name="organizationName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your organization Name",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter organization name"
                    className="ContactFormInput"

                  />
                </Form.Item>

                <Form.Item
                  label="Organization Description*"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input your organization description",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Enter organization description"
                    className="ContactFormInput"

                  />
                </Form.Item>

                <Form.Item
                  label="Message*"
                  name="message"
                  rules={[
                    {
                      required: true,
                      message: "Please input your message",
                    },
                  ]}
                >
                  <Input.TextArea
                    size="large"
                    placeholder="Enter Message"
                    className="ContactFormTextField"
                    rows={4}

                  />
                </Form.Item>



                <br />

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="loginButton"
                  >
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </Col>
      </Row>
    </Layout>
  );

}

export default Requests;
